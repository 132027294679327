import initEcwidApp from './initEcwidApp';
import initI18N from './i18n';
import initSentry from './sentry';
import store from '../store';
import api, { apiInstance } from '../api';
import getSearchParams from '../utils/getSearchParams';
import searchParamsConstant from '../constants/searchParams';
import { setProfileData } from '../store/slices/profileSlice';
import mainPreloader from '../utils/mainPreloader';

async function performBoots() {
  initSentry();
  initI18N();
  const { EcwidApp } = await initEcwidApp(false, false);

  const ecwidPayload = getSearchParams(searchParamsConstant.ecwidPayload);
  const baseParams = { payload: ecwidPayload };
  apiInstance.setBaseParams(baseParams);
  const profileData = await api.ecwidRepository.getProfile();
  store.dispatch(setProfileData(profileData));
  mainPreloader.showMainPreloader();
  EcwidApp.ready();
}
export default performBoots;
