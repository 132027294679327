import Repository from './Repository';
import promotionsDTO from '../../DTOs/promotionsDTO';
import apiURLs from '../../constants/apiURLs';

class ExampleRepository extends Repository {
  async exampleRequest() {
    return await this.request.get(this.services.exampleService);
  }

  getTimeSlots = async (promotionID) => {
    const data = {
      url: apiURLs.example.getTimeSlots(promotionID)
    };
    const response = await this.request.get(this.services.exampleService, data);
    return promotionsDTO.getTimeSlots(response.data);
  };
  createTimeSlot = async (promotionID) => {
    const data = {
      url: apiURLs.example.createTimeSlot(promotionID)
    };
    const response = await this.request.post(this.services.exampleService, data);
    return promotionsDTO.createTimeSlot(response.data);
  };
  postTimeSlot = async (promotionID,timeslotID, timeslot) => {
    const data = {
      url: apiURLs.example.postTimeSlot(promotionID, timeslotID),
      data: timeslot
    };
    const response = await this.request.put(this.services.exampleService, data);
    return promotionsDTO.getTimeSlot(response.data);
  };
  deleteTimeSlot = async (promotionID,timeslotID) => {
    const data = {
      url: apiURLs.example.deleteTimeSlot(promotionID, timeslotID),
    };
    await this.request.delete(this.services.exampleService, data);
  };
}

export default ExampleRepository;
