import dayjs from 'dayjs';
import store from '../store';
import getSearchParams from '../utils/getSearchParams';
import searchParamsConstant from '../constants/searchParams';
import { getFullDate } from '../utils/dateUtils';

const promotionsDTO = {
  getPromotionsList(promotionsListRemote) {
    return promotionsListRemote.map((promotionRemote) =>
      this.promotionRemoteToPromotionShort(promotionRemote)
    );
  },
  getPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  getTimeSlots(timeSlotsRemote) {
    return timeSlotsRemote.map((timeSlotRemote) =>
      this.timeSlotsRemoteToTimeSlots(timeSlotRemote)
    );
  },
  createTimeSlot(timeSlotRemote) {
    return this.timeSlotsRemoteToTimeSlots(timeSlotRemote);
  },
  getTimeSlot(timeSlotRemote) {
    return this.timeSlotsRemoteToTimeSlots(timeSlotRemote);
  },
  setPromotionSettings(promotion) {
    return this.promotionToPromotionRemote(promotion);
  },
  promotionRemoteToPromotionShort(promotionRemote) {
    const { id, isActive, title } = promotionRemote;
    return {
      id,
      enabled: isActive,
      title
    };
  },
  createPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  setTimeSlots(timeSlotsData) {
    const { dateFrom, dateTo, timeFrom, timeTo, restrictDate, restrictTime, repeat, startDay } = timeSlotsData;
    let startAtDate = '';
    let endAtDate = '';
    let restrictDayDate = '';
    if (dateFrom) {
      let fullDateFrom = getFullDate(timeFrom, dateFrom);
      startAtDate = dayjs(fullDateFrom).format('YYYY-MM-DD HH:mm:ssZ');
    }
    if (dateTo) {
      let fullDateTo = getFullDate(timeTo, dateTo);
      endAtDate = dayjs(fullDateTo).format('YYYY-MM-DD HH:mm:ssZ');
    }
    if (restrictDate) {
      let fullDateTo = getFullDate(restrictTime, restrictDate);
      restrictDayDate = dayjs(fullDateTo).format('YYYY-MM-DD HH:mm:ssZ');
    }
    return {
      startAt: startAtDate,
      endAt: endAtDate,
      restrictDay: restrictDayDate,
      repeat,
      startDay
    };
  },
  timeSlotsRemoteToTimeSlots(timeSlotRemote) {
    const { id, startAt, endAt, repeat, startDay, restrictDay } = timeSlotRemote;
    let startAtDate;
    let endAtDate;
    let restrictDayDate;
    if (startAt) {
      startAtDate = new Date(startAt);
    }
    if (endAt) {
      endAtDate = new Date(endAt);
    }
    if (restrictDay) {
      restrictDayDate = new Date(restrictDay);
    }
    return {
      id,
      dateFrom: startAtDate,
      dateTo: endAtDate,
      timeFrom: startAtDate,
      timeTo: endAtDate,
      restrictDay: restrictDayDate,
      restrictTime: restrictDayDate,
      repeat,
      startDay
    };
  },
  promotionRemoteToPromotion(promotionRemote) {
    const { id, isActive, parameter, title, categories } = promotionRemote;
    let categoriesToBuy = categories ?? [];
    let products = promotionRemote.products ?? { items: [] };
    const { name: parameterName, value: parameterValue } = parameter;
    const { items: productsToBuy } = products;
    const { currencySuffix, currencyPrefix } = store.getState().profileReducer.formatsAndUnits;
    return {
      id,
      enabled: isActive,
      meaning: String(parameterValue),
      description: title,
      parameter: parameterName,
      productsToBuy: productsToBuy.map((productToBuyRemote) =>
        this.productRemoteToProduct(productToBuyRemote, { currencyPrefix, currencySuffix })
      ),
      categoriesToBuy: categoriesToBuy
    };
  },
  setCategories(categoriesData) {
    const { categoriesToBuy } = categoriesData;
    let categories;
    if (categoriesToBuy !== undefined && Array.isArray(categoriesToBuy)) {
      categories = categoriesToBuy;
    }
    return {
      categoryIds: categories.map((category) => category.id)
    };
  },
  promotionToPromotionRemote(promotion) {
    const {
      description,
      parameter,
      meaning
    } = promotion;
    const promotionRemote = {};
    if (description !== undefined) {
      promotionRemote.title = description;
    }
    if (parameter !== undefined && meaning !== undefined) {
      promotionRemote.parameter = {
        name: parameter.key,
        value: parseFloat(meaning || '0')
      };
    }
    return promotionRemote;
  },
  productRemoteToProduct(productRemote, currency) {
    const { id, name, originalImageUrl, sku, price } = productRemote;
    let currencyPrefix;
    let currencySuffix;
    if (!currency) {
      const { formatsAndUnits } = store.getState().profileReducer;
      currencyPrefix = formatsAndUnits.currencyPrefix;
      currencySuffix = formatsAndUnits.currencySuffix;
    } else {
      currencyPrefix = currency.currencyPrefix;
      currencySuffix = currency.currencySuffix;
    }
    const priceWithCurrency = `${currencyPrefix} ${price} ${currencySuffix}`;
    return {
      id,
      name,
      imageUrl: originalImageUrl || '',
      vendorCode: sku,
      price: priceWithCurrency
    };
  },
  getProductsList: {
    remoteToDomain(products, pagination) {
      const { currencySuffix, currencyPrefix } = store.getState().profileReducer.formatsAndUnits;
      const { currentPage, lastPage: totalPages, perPage: itemsPerPage } = pagination ?? {
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: 20
      };
      let curPage = parseInt(currentPage);
      return {
        pagination: {
          currentPage: curPage,
          totalPages,
          itemsPerPage
        },
        products: products.map((product) =>
          promotionsDTO.productRemoteToProduct(product, { currencyPrefix, currencySuffix })
        )
      };
    },
    params(promotionID, search, page) {
      const params = {};
      if (!promotionID) {
        params.promotionID = promotionID;
      } else {
        params.promotionID = getSearchParams(searchParamsConstant.promotionID) ?? '';
      }
      params.search = search ?? '';
      params.page = page ?? 1;
      return params;
    }
  },
  getCategoriesList: {
    remoteToDomain(categoriesRemote) {
      return categoriesRemote;
    },
    params(search) {
      const params = {};
      params.search = search ?? '';
      return params;
    }
  },
  setProducts(productsData) {
    const { productsToBuy } = productsData;
    let products = [];
    if (productsToBuy !== undefined && Array.isArray(productsToBuy) && productsToBuy.length) {
      products = productsToBuy;
    }
    return {
      productIds: products.map((product) => product.id)
    };
  }
};

export default promotionsDTO;
