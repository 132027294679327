const apiURLs = {
  promotion: {
    basePrefix: 'promotions',
    createPromotion: '',
    getPromotionsList: '',
    setPromotion: (promotionID) => `${promotionID}`,
    getPromotion: (promotionID) => `${promotionID}`,
    enablePromotion: (promotionID) => `${promotionID}/enable`,
    disablePromotion: (promotionID) => `${promotionID}/disable`,
    deletePromotion: (promotionID) => `${promotionID}`,
    searchPromotionCategories: (promotionID) => `${promotionID}/categories/search`,
    setPromotionProducts: (promotionID) => `${promotionID}/products`,
    getPromotionProductsList: (promotionID) => `${promotionID}/products`,
    searchPromotionProducts: (promotionID) => `${promotionID}/products/search`,
  },
  ecwid: {
    basePrefix: '',
    getProfile: 'profile',
  },
  example: {
    basePrefix: 'https://638492f14ce192ac605be53a.mockapi.io/api/ext/promotions',
    getTimeSlots: (promotionID) => `${promotionID}/timeslots`,
    createTimeSlot: (promotionID) => `${promotionID}/timeslots`,
    postTimeSlot: (promotionID, timeslotID) => `${promotionID}/timeslots/${timeslotID}`,
    deleteTimeSlot: (promotionID, timeslotID) => `${promotionID}/timeslots/${timeslotID}`,
  },
};

export default apiURLs;
