import axios from 'axios';
import { camelToSnake, snakeToCamel } from '../DTOs/TransformCase';
import RequestError from './errors/RequestError';
import ResponseError from './errors/ResponseError';
import AbortError from './errors/AbortError';

class AxiosAPI {
  _baseURL;
  _axiosInstance;
  _refreshTokenObject = null;
  _accessToken = {
    bearer: 'Bearer',
    token: '',
  };

  constructor(baseURL = '', baseParams) {
    this._baseURL = baseURL;
    this._baseParams = baseParams;
    this._axiosInstance = axios.create({
      baseURL: baseURL,
      // Временно
      headers: { 'Accept-Language': 'ru' },
    });
    this._initInterceptors();
  }

  async request(data) {
    return await this._axiosInstance(data);
  }

  getAccessToken(withBearer) {
    return `${withBearer ? `${this._accessToken.bearer}` : ''}${this._accessToken.token}`;
  }

  setAccessToken(accessToken) {
    this._accessToken.token = accessToken;
    this._axiosInstance.defaults.headers.common['Authorization'] = this.getAccessToken(true);
  }

  removeAccessToken() {
    this._accessToken.token = '';
    this._axiosInstance.defaults.headers.common['Authorization'] = '';
  }

  get baseURL() {
    return this._baseURL;
  }

  set baseURL(value) {
    this._baseURL = value;
  }

  getBaseParams() {
    return this._baseParams;
  }

  setBaseParams(value) {
    this._baseParams = value;
  }

  addBaseParams(value) {
    this._baseParams = { ...this._baseParams, ...value };
  }

  setRefreshTokenObject(refreshToken) {
    this._refreshTokenObject = refreshToken;
  }

  getRefreshTokenObject() {
    return this._refreshTokenObject;
  }

  removeRefreshTokenObject() {
    this._refreshTokenObject = null;
  }

  _initInterceptors() {
    this._successResponseInterceptor = this._successResponseInterceptor.bind(this);
    this._failureResponseInterceptor = this._failureResponseInterceptor.bind(this);
    this._requestInterceptor = this._requestInterceptor.bind(this);
    this._axiosInstance.interceptors.response.use(
      this._successResponseInterceptor,
      this._failureResponseInterceptor
    );
    this._axiosInstance.interceptors.request.use(this._requestInterceptor);
  }

  _requestInterceptor(data) {
    if (data.headers['Content-Type'] === 'multipart/form-data') return data;
    data.data = camelToSnake(data.data);
    data.params = { ...this._baseParams, ...data.params };
    return data;
  }

  _successResponseInterceptor(response) {
    if (response.headers['content-type'] === 'application/json') {
      const { data, meta = null } = response.data;
      const transformedResponse = snakeToCamel({ data, meta });
      return Promise.resolve(transformedResponse);
    } else {
      return Promise.resolve(response);
    }
  }

  // Dumb fix
  static advancedOrMessage(e) {
    const advancedArray = e.advanced[Object.keys(e.advanced)[0]];
    return advancedArray?.[0] ?? e.message;
  }

  async _failureResponseInterceptor(error) {
    let errorBody;
    let returnedError;
    try {
      if (error.name === 'CanceledError') {
        returnedError = new AbortError(error.message);
      } else if (!error.response || error.response.status === 500) {
        const status = error.response?.status ? 500 : null;
        const message = error.response ? 'Потеря соединения' : 'Ошибка сервера';
        errorBody = { status };
        returnedError = new RequestError(errorBody, message);
      } else {
        const { data, status } = error.response;
        const { code, message, advanced } = data.error;
        const transformedAdvanced = snakeToCamel(advanced);

        const humanReadableMessage = AxiosAPI.advancedOrMessage({
          message,
          advanced,
        });

        errorBody = { status, code, advanced: transformedAdvanced };
        returnedError = new ResponseError(errorBody, humanReadableMessage);
      }
      switch (returnedError.body.status) {
        case 401: {
          const isRefreshTokenRequest =
            error.response?.config.url === this._refreshTokenObject?.url;
          if (!isRefreshTokenRequest && this._refreshTokenObject) {
            const originalRequest = error.config;
            const response = await this._refreshTokenObject.request();
            if (response.status === 'ok') {
              originalRequest.headers.Authorization = this.getAccessToken(true);
              try {
                originalRequest.data = JSON.parse(originalRequest.data);
                return await this._axiosInstance.request(originalRequest);
              } catch (e) {
                return Promise.reject(e);
              }
            } else {
              returnedError = new ResponseError(
                { status: 401, advanced: {} },
                'Ошибка авторизации'
              );
            }
          }
          break;
        }
        // no default
      }
      return Promise.reject(returnedError);
    } catch (e) {
      errorBody = {
        status: null,
      };
      returnedError = new RequestError(errorBody, 'Непредвиденная ошибка в обработке ответа');
      return Promise.reject(returnedError);
    }
  }
}
export default AxiosAPI;
