export default {
  commonTexts: {
    button: {
      save: 'Сохранить',
      cancel: 'Отменить',
      back: 'Назад'
    },
    removeModal: {
      label: 'Вы действительно хотите удалить лейбл?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
    tableProductsHeader: {
      name: 'Название',
      vendorCode: 'Артикул',
      price: 'Цена',
      action: 'Действие'
    },
    multiselect: {
      submitButton: 'Добавить к акции',
      cancelButton: 'Отмена',
      selectAllButton: 'Выбрать все',
      selectNoneButton: 'Отменить выделение',
      products: {
        placeholder: 'Название товара или артикул',
        pageText: 'Страница',
        emptyList: {
          title: 'Нет такого товара',
          description: 'Попробуйте другое название или очистите поиск, чтобы посмотреть все товары'
        }
      },
      categories: {
        placeholder: 'Название категории или артикул',
        emptyList: {
          title: 'Нет такой категории',
          description:
            'Попробуйте другое название или очистите поиск, чтобы посмотреть все категории'
        }
      }
    }
  },
  indexPage: {
    bundlesAreaTitle: 'Комплекты магазина',
    bundlesAreaSubtitle:
      'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно',
    pageTitle: 'Комплекты',
    addLabelAreaTitle: 'Привлеките внимание к товарам',
    addLabelAreaSubtitle:
      'Объединяйте товары в наборы со скидкой, чтобы Покупатель отправлял в корзину сразу несколько позиций и тратил больше денег в вашем магазине',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'Особенности работы приложения, которые необходимо учитывать при его использовании. Если у вас возникнут вопросы или трудности - <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">свяжитесь с нами</a>, мы обязательно поможем'
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.'
      },
      question2: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.'
      }
    },
    iconableBlockTitle: 'Собрать комплект',
    iconableBlockDescription:
      'Создавайте комплекты, объединенные общей идеей: наборы к праздникам или комплементарные товары. Подскажите Покупателю, что дополнит его корзину, и подкрепите предложение заманчивой скидкой',
    addNewBundle: 'Добавить комплект',
    card: {
      action: 'Действия',
      actionUpdate: 'Редактировать',
      actionDelete: 'Удалить'
    },
    removeBundleModal: {
      label: 'Вы действительно хотите удалить комплект?',
      agreeButton: 'Да',
      cancelButton: 'Нет'
    }
  },
  homePage: {
    title: 'Акции',
    addToShopArea: {
      title: 'Добавить акцию в магазин',
      subtitle: 'Здесь вы можете настроить параметры акции на товары.',
      interactiveBlock: {
        title: 'Создать акцию',
        subtitle:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
        buttonText: 'Создать акцию'
      }
    },
    promotionsArea: {
      title: 'Список акций',
      subtitle:
        'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно.',
      interactiveBlock: {
        emptyBlockTitle: 'Список акций пуст',
        emptyBlockErrorTitle: 'Не удалось загрузить список акций',
        actionButton: {
          title: 'Действия',
          actionsList: {
            edit: 'Редактировать',
            delete: 'Удалить'
          }
        }
      }
    },
    shopRevisionArea: {
      title: 'Доработайте магазин под ваши нужды',
      subtitle:
        'Иногда просто скидки недостаточно: нужно подсказать покупателю, какой товар еще добавить в корзину.',
      interactiveBlock: {
        title: 'Товарные рекомендации',
        subtitle:
          'Помогите клиенту собрать все товары для 1+1=3 и предложите ему аксессуары, сопутствующие товары или аналоги подороже!',
        buttonText: 'Создать акцию'
      }
    }
  },
  detailPromotionPage: {
    title: 'Настройка акции',
    setupPromotionArea: {
      title: 'Настройка акции в магазин',
      subtitle: 'Здесь вы можете настроить параметры акции на товары.',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Включить акцию на товары'
        },
        section_2: {
          title_1: 'Акция',
          parameter: {
            title: 'Параметр',
            option_1: 'Назначить скидку на комплект',
            option_2: 'Назначить цену на комплект'
          },
          meaning: {
            title: 'Значение'
          },
          title_2: 'Описание акции для покупателей',
          descriptionPlaceholder: 'Введите описание',
        },
        section_3: {
          title_1: 'Начало акции',
          dateBlock: {
            title_1: 'Время',
            title_2: 'Дата'
          },
          checkboxBlock: {
            text: 'Не учитывать время начала'
          }
        },
        section_4: {
          title_1: 'Завершение акции',
          dateBlock: {
            title_1: 'Время',
            title_2: 'Дата'
          },
          checkboxBlock: {
            text: 'Не учитывать время завершения'
          },
          error: {
            text: 'Дата завершения должна быть больше даты начала'
          }
        }
      }
    },
    setupTimeSlotsArea: {
      title: 'Настроить временные отрезки',
      subtitle: 'Временные отрезки позволяют управлять началом и завершением акции, а также настроить ее повтор еженедельно или ежемесячно с ограничением до конкретной даты.',
      interactiveBlock: {
        title: 'Время работы акции',
        button:'Добавить слот',
        section_1: {
          title_1:'Начало акции',
          placeholder_1:'Время',
          title_2:'Завершение акции',
          placeholder_2:'Дата',
        },
        section_2:{
          title_1:'Повтор акции',
          option_1:'Не повторять',
          option_2:'Еженедельно - с выбором дня',
          option_3:'Ежемесячно - ',
          option_4_start:'Повторять каждое',
          option_4_ending:'число месяца',
          weekdays:['с воскресенья кадж.', 'с понедельника кадж.', 'со вторника кадж.', 'со среды кадж.','с четверга кадж.','с пятницы кадж.','с субботы кадж.'],
          option_3_ending:'-й недели',
          daysOptions:['Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье'],
          title_2:'Стартовый день',
          daysSelectPlaceholder:'Выберите день'
        },
        section_3: {
          title:'Ограничить акцию до',
          checkboxText:'Не ограничивать акцию'
        },
        section_4: {
          question:'Вы действительно хотите удалить временной слот?',
          agree:'Да',
          cancel:'Нет',
        }
      }
    },
    setupPurchaseArea: {
      interactiveBlock: {
        productsBinding: {
          title: 'Вручную привязать товары',
          subtitle: 'Категории группируют товары вместе и отображают их на отдельной странице магазина. Товар можно привязать сразу к нескольким категориям, чтобы покупателям было проще его найти.',
          buttonAddItemsText:['товаров', 'товара', 'товар']
        },
        productCategoriesBinding: {
          title: 'Выбрать данные о категории товара',
          subtitle: 'Категории группируют товары вместе и отображают их на отдельной странице магазина. Товар можно привязать сразу к нескольким категориям, чтобы покупателям было проще его найти.'
        }
      }
    },
    labelsArea:{
      title: 'Настройка лейблов',
      subtitle: 'Здесь вы можете настроить параметры лейблов на товарах. Оповестить покупателей о начале акции и о её завершении.',
      interactiveBlock: {
        beforeAction:{
          title:'Добавить лейбл перед началом акции',
          description:'Разместите наклейки на карточках товаров, оповещающие о начале акции или о её завершении.',
          inputTitle:'Лейбл до начала акции'
        },
        duringAction:{
          title:'Добавить лейбл во время акции',
          description:'Разместите наклейки на карточках товаров, оповещающие о начале акции или о её завершении.',
          inputTitle:'Лейбл во время акции'
        },
        colorPicker: {
          title: 'Цвет лейбла',
          customColor: 'Собственный цвет лейбла',
        },
        input: {
          inputPlaceholder: 'Введите текст лейбла',
        },
        helpText: 'Вы можете вставлять в текст лейбла переменные: {finish_time}, {finish_date}',
        helpBubble:'{finish_time} - время окончания акции; {finish_date} - дата окончания акции',
        ribbonPreview:'Предпросмотр лейбла',
        deleteRibbon:'Удалить созданные лейблы',
      },
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        'Если у вас возникнут вопросы или трудности — свяжитесь с нами, мы обязательно поможем. При необходимости вы можете отключить акцию, пока мы решаем проблему'
    }
  }
};
