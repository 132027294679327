import store from '../store';
import { enableMainPreloader } from '../store/slices/mainPreloaderSlice.js';

export default {
  showMainPreloader() {
    store.dispatch(enableMainPreloader(true));
  },
  hideMainPreloader() {
    store.dispatch(enableMainPreloader(false));
  },
};
