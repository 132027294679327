export default {
  commonTexts: {
    button: {
      save: 'Save',
      cancel: 'Cancel',
      back: 'Back'
    },
    removeModal: {
      label: 'Do you really wish to delete the ribbon?',
      agreeButton: 'Yes',
      cancelButton: 'No'
    },
    tableProductsHeader: {
      name: 'Name',
      vendorCode: 'Vendor code',
      price: 'Price',
      action: 'Action'
    },
    multiselect: {
      submitButton: 'Add to actions',
      cancelButton: 'Cancel',
      selectAllButton: 'Select all',
      selectNoneButton: 'Select none',
      products: {
        placeholder: 'Product name or article number',
        pageText: 'Page',
        emptyList: {
          title: 'There isn\'t such product',
          description: 'Try a different name or clear your search to see all products'
        }
      },
      categories: {
        placeholder: 'Category name or article number',
        emptyList: {
          title: 'There isn\'t such category',
          description: 'Try a different name or clear your search to see all categories'
        }
      }
    }
  },
  indexPage: {
    bundlesAreaTitle: 'Product bundles',
    bundlesAreaSubtitle:
      'You can edit and delete bundles, as well as enable and disable them without deleting',
    pageTitle: 'Product Bundles',
    addLabelAreaTitle: 'Highlight your products',
    addLabelAreaSubtitle:
      'Combine different items to sell them together with a discount. Motivate customers to add more products to their carts and spend more money!',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'A detailed guide on how to use an app. If you need any help or have any problems please don\'t hesitate to <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">contact us</a> directly. We are here to help'
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.'
      }
    },
    iconableBlockTitle: 'Bundle products',
    iconableBlockDescription:
      'Create bundles that make sense, such as holiday sets or complementary goods combo. Help your customers to find the best addition to their carts and offer the discount for the bundle',
    addNewBundle: 'Create product bundles',
    card: {
      action: 'Actions',
      actionUpdate: 'Edit',
      actionDelete: 'Remove'
    },
    removeBundleModal: {
      label: 'Do you really want to remove this bundle?',
      agreeButton: 'Agree',
      cancelButton: 'Cancel'
    }
  },
  homePage: {
    title: 'Actions',
    addToShopArea: {
      title: 'Add action to the shop',
      subtitle: 'Here you can set the promotion parameters for products.',
      interactiveBlock: {
        title: 'Create action',
        subtitle:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
        buttonText: 'Create action'
      }
    },
    promotionsArea: {
      title: 'Actions list',
      subtitle:
        'You can edit and delete packages, as well as temporarily disable and enable offers.',
      interactiveBlock: {
        emptyBlockTitle: 'Actions list is empty',
        emptyBlockErrorTitle: 'Failed to load actions list',
        actionButton: {
          title: 'Actions',
          actionsList: {
            edit: 'Edit',
            delete: 'Delete'
          }
        }
      }
    },
    shopRevisionArea: {
      title: 'Tailor the store to your needs',
      subtitle:
        'Sometimes just a discount is not enough: you need to tell the buyer which product to add to the cart.',
      interactiveBlock: {
        title: 'Product recommendations',
        subtitle:
          'Help the client collect all the goods for 1+1=3 and offer him accessories, related products or more expensive analogues!',
        buttonText: 'Create action'
      }
    }
  },
  detailPromotionPage: {
    title: 'Action setup',
    setupPromotionArea: {
      title: 'Action setup',
      subtitle: 'Here you can manage the promotion parameters.',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Enable action'
        },
        section_2: {
          title_1: 'Action',
          parameter: {
            title: 'Parameter',
            option_1: 'Assign discount',
            option_2: 'Set price'
          },
          meaning: {
            title: 'Meaning'
          },
          title_2: 'Description of the promotion for buyers',
          descriptionPlaceholder: 'Enter a description'
        },
        section_3: {
          title_1: 'Promotion start',
          dateBlock: {
            title_1: 'Time',
            title_2: 'Date'
          },
          checkboxBlock: {
            text: 'Ignore start time'
          }
        },
        section_4: {
          title_1: 'Promotion end',
          dateBlock: {
            title_1: 'Time',
            title_2: 'Date'
          },
          checkboxBlock: {
            text: 'Ignore end time'
          },
          error: {
            text: 'End date should be grater than start date'
          }
        }
      }
    },
    setupTimeSlotsArea: {
      title: 'Time slots setup',
      subtitle: 'Time slots allow you to manage the start and end of the promotion, as well as set it up to repeat weekly or monthly with a limit until a specific date',
      interactiveBlock: {
        title: 'Promotion time',
        button:'Add time slot',
        section_1: {
          title_1:'Promotion start',
          placeholder_1:'Time',
          title_2:'Promotion end',
          placeholder_2:'Date',
        },
        section_2:{
          title_1:'Promotion repeat',
          option_1:'Do not repeat',
          option_2:'Every week - with day selection',
          option_3:'Every month - ',
          option_4_start:'Repeat every',
          option_4_ending:'day of month',
          weekdays:['from sunday every', 'from monday every', 'from tuesday every', 'from wednesday every','from thursday every','from friday every','from saturday every'],
          option_3_ending:' week',
          daysOptions:['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
          title_2:'Start day',
          daysSelectPlaceholder:'Select day of the week'
        },
        section_3: {
          title:'Restrict to',
          checkboxText:'Do not restrict promotion'
        },
        section_4: {
          question:'Are you sure you want to delete the time slot?',
          agree:'Yes',
          cancel:'No',
        }
      }
    },
    setupPurchaseArea: {
      interactiveBlock: {
        productsBinding: {
          title: 'Linking products for purchase',
          subtitle:
            'Select the products after the purchase of which the promotion will be available.',
          buttonAddItemsText: ['items', 'items', 'item']
        },
        productCategoriesBinding: {
          title: 'Linking product categories for purchase',
          subtitle: 'Select the categories from which the products will be included in the promotion.'
        }
      }
    },
    labelsArea: {
      title: 'Ribbons setup',
      subtitle: 'Here you can configure ribbons settings for products. Notify buyers about the start of the promotion and its end.',
      interactiveBlock: {
        beforeAction: {
          title: 'Add ribbon before action start',
          description: 'Add ribbons on product cards that announce the start of the promotion or its end.',
          inputTitle: 'Ribbon before action start'
        },
        duringAction: {
          title: 'Add ribbon during action',
          description: 'Add ribbons on product cards that announce the start of the promotion or its end.',
          inputTitle: 'Ribbon during action'
        },
        colorPicker: {
          title: 'Ribbon color',
          customColor: 'Custom Ribbon Color'
        },
        input: {
          inputPlaceholder: 'Add ribbon text'
        },
        helpText: 'You can insert variables into the ribbon text: {finish_time}, {finish_date}',
        helpBubble: '{finish_time} - promotion finish time; {finish_date} - promotion finish date',
        ribbonPreview: 'Ribbon preview',
        deleteRibbon: 'Delete created labels'
      }
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        'If you have any questions or difficulties, please contact us, we will definitely help. If necessary, you can disable the promotion while we resolve the issue.'
    }
  }
};
