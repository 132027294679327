import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formatsAndUnits: {},
};

const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setProfileData(state, action) {
      const { formatsAndUnits } = action.payload;
      const { currency, currencySuffix, currencyPrefix } = formatsAndUnits;
      const currencySign = currencySuffix || currencyPrefix;
      state.formatsAndUnits = { currency, currencySuffix, currencyPrefix, currencySign };
    },
  },
});

const { reducer, actions } = profileSlice;
const { setProfileData } = actions;

export { setProfileData };
export default reducer;
