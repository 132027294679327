class Service {
  constructor(api, baseURL = '') {
    this._baseURL = baseURL;
    this._api = api;
  }

  async request(data) {
    if (this._baseURL) {
      data.baseURL = this._baseURL;
    }
    try {
      return await this._api.request(data);
    } catch (e) {
      console.log('Service request error', e);
      throw e;
    }
  }
}

export default Service;
