import Service from './Service';
import environment from '../../constants/environment';

function createServices(api) {
  return {
    exampleService: new Service(api, 'https://638492f14ce192ac605be53a.mockapi.io/api/ext/'),
    baseService: new Service(api, environment.REACT_APP_BASE_API_URL),
  };
}

export default createServices;
