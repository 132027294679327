import Repository from './Repository';
import promotionsDTO from '../../DTOs/promotionsDTO';
import apiURLs from '../../constants/apiURLs';

class PromotionRepository extends Repository {
  getPromotionsList = async () => {
    const data = {
      url: apiURLs.promotion.getPromotionsList
    };
    const response = await this.request.get(this.services.baseService, data);
    return promotionsDTO.getPromotionsList(response.data.items);
  };

  createPromotion = async () => {
    const data = {
      url: apiURLs.promotion.createPromotion
    };
    const response = await this.request.post(this.services.baseService, data);
    return promotionsDTO.createPromotion(response.data);
  };

  setPromotionEnable = async (promotionID, enable) => {
    const data = {
      url: enable
        ? apiURLs.promotion.enablePromotion(promotionID)
        : apiURLs.promotion.disablePromotion(promotionID),
    };
    await this.request.post(this.services.baseService, data);
  };

  deletePromotion = async (promotionID) => {
    const data = {
      url: apiURLs.promotion.deletePromotion(promotionID)
    };
    try {
      await this.request.delete(this.services.baseService, data);
    } catch (e) {
      console.error(e);
    }
  };

  getPromotion = async (promotionID) => {
    const data = {
      url: apiURLs.promotion.getPromotion(promotionID)
    };
    const response = await this.request.get(this.services.baseService, data);
    return promotionsDTO.getPromotion(response.data);
  };

  setPromotionSettings = async (promotionID, promotion) => {
    const promotionRemote = promotionsDTO.setPromotionSettings(promotion);
    const data = {
      url: apiURLs.promotion.setPromotion(promotionID),
      data: promotionRemote,
    };
    await this.request.post(this.services.baseService, data);
  };

  setCategories = async (promotionID, categoriesData) => {
    const setCategoriesData = promotionsDTO.setCategories(categoriesData);
    const data = {
      url: apiURLs.promotion.setPromotion(promotionID),
      data: setCategoriesData,
    };
    await this.request.post(this.services.baseService, data);
  };

  setProducts = async (promotionID, productsData) => {
    const setProductsData = promotionsDTO.setProducts(productsData);
    const data = {
      url: apiURLs.promotion.setPromotionProducts(promotionID),
      data: setProductsData,
    };
    await this.request.post(this.services.baseService, data);
  };

  getProductsList = async (promotionID, search, page) => {
    const getProductsListParams = promotionsDTO.getProductsList.params(
      promotionID,
      search,
      page
    );
    const data = {
      url: apiURLs.promotion.searchPromotionProducts(promotionID),
      params: getProductsListParams,
    };
    const response = await this.requestAborted.get(
      'getProductsList',
      this.services.baseService,
      data
    );
    return promotionsDTO.getProductsList.remoteToDomain(response.data.items,response.data);
  };

  getCategoriesList = async (promotionID, search) => {
    const getCategoriesListParams = promotionsDTO.getCategoriesList.params(search);
    const data = {
      url: apiURLs.promotion.searchPromotionCategories(promotionID),
      params: getCategoriesListParams,
    };
    const response = await this.requestAborted.get(
      'getCategoriesList',
      this.services.baseService,
      data
    );
    return promotionsDTO.getCategoriesList.remoteToDomain(response.data);
  };
}

export default PromotionRepository;
